import { LoadDataErrors } from './types';

export enum PlatformEnum {
  'Feenics' = 1,
  'S2' = 2,
}

export enum notificationEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum selectInstanceModeEnum {
  searchMode = 'searchMode',
  regionMode = 'regionMode',
  territoryMode = 'territoryMode',
  serverMode = 'serverMode',
  instanceGroup = 'instanceGroup'
}

export type UniqueInstancePublicDataType = {
  // for multiselect in instances list page
  region: string[];
  server_name: string[];
  city: string[];
  country: string[];
  territory: string[];
};

export enum selectHardwareTypeEnum {
  //define hardware options(types)
  Camera = 'camera',
  Intercom = 'intercom',
  Downstream = 'downstream',
  Controller = 'controller',
  Reader = 'reader',
  Elevator = 'elevator',
  CountingArea = 'counting_area'
}

export type InstancePublicDataType = {
  //need for get instance public data
  id: number;

  instance_id: string;
  instance_name: string;
  server_id: number;
  server_name: string;

  platform: string;

  territory: string;
  region: string;
  timezone: string;
  city: string;
  instance_groups: string[];
};

export type ListInstancePublicDataType = {
  instances: InstancePublicDataType[];
}

export type InstanceExtendedPublicDataType = InstancePublicDataType & {
  // need for get instance extended public data
  ip_address?: string;
  secc_code: string;
  building_address?: string;
  country: string;
};

export type InstancePublicDataListResponseType = {
  //instances public data list response
  instances: InstancePublicDataType[];
};

export type InstancesExtendedPublicDataListResponseType = {
  // instances extended public data list response
  instances: InstanceExtendedPublicDataType[];
};

export type notificationType = {
  //notification type
  type: notificationEnum;
  description: string;
  created_at: number;
};

export type HardwareFavoriteDataType = {
  // hardware favorite objects
  hardware_id: string;
  hardware_type: selectHardwareTypeEnum;
};

export type HardwareFavoriteResponseType = {
  // hardware favorite response
  hardware_id: string;
  hardware_type: selectHardwareTypeEnum;
  status: string;
};

export type errorType = {
  messageError: string | null;
  typeError: LoadDataErrors;
};

export type nicknameType = {
  nickname: string;
  hardware_id: string;
  hardware_type: selectHardwareTypeEnum;
};

export type nicknameLogs = {
  nickname: string;
  user_email: string;
  created_at: string;
};
